import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion, Variants } from 'framer-motion';

import Container from 'components/organisms/Container';
import Banner from 'components/molecules/Banner';
import { PersonalCardProps } from 'components/molecules/PersonalCard/models';
import Button from 'components/atoms/Button';
import { ICONS_SVG } from 'components/atoms/IconsSVG';
import Seo from 'components/atoms/Seo';

import { isBrowser } from 'utils/browsers';
import { normalizeString } from 'utils/normalizeString';
import { useScreenService } from 'hooks/useScreenService';

const TrainerPage = ({
  data: {
    contentfulPersonnel: {
      aboutMeLabel,
      entitlementsLabel,
      experienceLabel,
      successesLabel,
      banner: { bannerSlide },
      trainers,
      backToPersonelReference: { buttonLabel, reference },
    },
  },
}) => {
  const trainerUrl = isBrowser() && window.location.pathname.split('/');

  const tempData = trainerUrl && trainerUrl[trainerUrl.length - 1].split('_');

  const data: PersonalCardProps = trainers?.filter(
    (trainer: PersonalCardProps) =>
      normalizeString(trainer?.name.toLowerCase()) === tempData[0] &&
      normalizeString(trainer?.surname.toLowerCase()) === tempData[1],
  )[0];

  const banner = {
    title: `${data?.name} ${data?.surname}`,
    image: bannerSlide[0].image,
  };

  const cardVariants: Variants = {
    hidden: {
      y: 50,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.8,
      },
    },
  };

  const { isMobile } = useScreenService();

  const renderTrainerSection = (label: string, text: string[], isList: boolean = false) => (
    <div className="trainer__section">
      <div className="trainer__section-label">{label}</div>
      {!isList ? (
        <div className="trainer__section-text">{text}</div>
      ) : (
        <ul className="trainer__section-list">
          {text.map((line: string) => (
            <li key={line} className="trainer__section-text">
              {line}
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  return (
    <div className="trainer">
      <Seo title={`${data?.name} ${data?.surname}`} />
      <Banner slides={[banner]} customClass="trainer__banner" />
      <Container>
        {data ? (
          <motion.div
            className="trainer__card"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
          >
            <GatsbyImage
              image={data.photo?.image.gatsbyImageData}
              alt={data.photo?.altText}
              className="trainer__photo"
            />
            <div className="trainer__content">
              {data.about ? renderTrainerSection(aboutMeLabel, [data.about.about]) : null}
              {data.entitlements
                ? renderTrainerSection(entitlementsLabel, data.entitlements, true)
                : null}
              {data.experience
                ? renderTrainerSection(experienceLabel, data.experience, true)
                : null}
              {data.successes ? renderTrainerSection(successesLabel, data.successes, true) : null}
            </div>
            <Button
              buttonLabel={isMobile ? '' : buttonLabel}
              variant="secondary-solid"
              customClass="trainer__back-button"
              icon={ICONS_SVG.VARIANT.ARROW_LEFT}
              iconSide="left"
              reference={reference}
            />
          </motion.div>
        ) : null}
      </Container>
    </div>
  );
};

export const query = graphql`
  {
    contentfulPersonnel {
      aboutMeLabel
      entitlementsLabel
      experienceLabel
      successesLabel
      banner {
        bannerSlide {
          image {
            altText
            image {
              gatsbyImageData
            }
          }
        }
      }
      trainers {
        name
        surname
        entitlements
        experience
        successes
        about {
          about
        }
        photo {
          altText
          image {
            gatsbyImageData
          }
        }
      }
      backToPersonelReference {
        buttonLabel
        reference {
          ... on ContentfulPersonnel {
            slug
          }
        }
      }
    }
  }
`;

export default TrainerPage;
